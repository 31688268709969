<template>
  <div class="cnz scrollable">
    <SimpleHero :is-foodleap="true" height="70vh" hide-logo/>
    <Container>
      <div class="cnz__info">
        <div class="cnz__cards">
          <div v-for="data in cardData" class="col-xs-12 col-sm-10 col-md-6 col-lg-4 cnz__card"
               style="color: white;">
            <h2>{{ data.title }}</h2>
            <div class="line"></div>
            <p class="cnz__cards__description" style="font-size: 15px; height: 100px;">{{ data.description }}</p>
            <div class="cnz__cards__actors">
              <h4>{{ data.subtitle }}</h4>
              <div class="cnz__card_content" v-if="data.key === 'latest_actors_1'">
                <div v-for="item in firstColumnActors" class="cnz__card_item" @click="goToActorDetailPage(item)">
                  <avatar :src="item.logo" :alt="item.name" variant="border"></avatar>
                  <p style="font-weight: 700;">{{ item.name }}</p>
                </div>
              </div>
              <div class="cnz__card_content" v-else-if="data.key === 'latest_actors_2'">
                <div v-for="item in secondColumnActors" class="cnz__card_item" @click="goToActorDetailPage(item)">
                  <avatar :src="item.logo" :alt="item.name" variant="border"></avatar>
                  <p style="font-weight: 700;">{{ item.name }}</p>
                </div>
              </div>
              <div class="cnz__card_content" v-else-if="data.key === 'latest_actors_3'">
                <div v-for="item in thirdColumnActors" class="cnz__card_item" @click="goToActorDetailPage(item)">
                  <avatar :src="item.logo" :alt="item.name" variant="border"></avatar>
                  <p style="font-weight: 700;">{{ item.name }}</p>
                </div>
              </div>
              <div class="cnz__card_content" v-else-if="data.key === 'latest_actors_4'">
                <div v-for="item in fourthColumnActors" class="cnz__card_item" @click="goToActorDetailPage(item)">
                  <avatar :src="item.logo" :alt="item.name" variant="border"></avatar>
                  <p style="font-weight: 700;">{{ item.name }}</p>
                </div>
              </div>
              <div class="static-home-foodleap__card_button" @click="goToViewMore(data)">
                <p style="margin: 0;">{{ data.buttonText }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>

    <div class="cnz__info--click-explore">
      <Container>
        <div style="padding-top: 3rem; padding-bottom: 3rem;">
          <h1 class="mb-05 dm-sans-font">{{ clickAndExploreTitle }}</h1>
          <p v-html="clickAndExploreDescription"></p>
        </div>
      </Container>
    </div>

    <Container style="margin-top: 3rem; margin-bottom: 3rem;">
      <h1 style="margin-bottom: 1rem;">{{ actorNumbersTitle }}</h1>
      <facet-card
        :source="'home'"
        :facet-counts="facetCounts"
        @click="facetCardClick"
      />
    </Container>

    <div v-if="!fetchingEvents" class="cnz__upcoming-event">
      <Container>
        <div class="cnz_events">
          <div v-if="events.length === 0">
            <div class="cnz_last_event">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 cnz_card_info">
                <h1>{{ $t('home_foodvalley_event_title') }}</h1>
                <h4>There are no upcoming events right now.</h4>
                <ds-button
                  variant="secondary"
                  :label="$t('home_foodvalley_see_all_events')"
                  :size="null"
                  :style="registerButtonStyle"
                  @click="goToEvents"
                />
              </div>
            </div>
          </div>
          <div v-for="item in events" class="cnz_last_event reverse">
            <div class="col-12 col-sm-12 col-md-7 col-lg-7 cnz_card_info">
              <h1>{{ $t('home_foodvalley_event_title') }}</h1>
              <h1>{{ item.title }}</h1>
              <div class="cnz__date">
                <icon name="calendar-empty" size="22"/>
                <p v-html="getFormatEventDate(item, true)"></p>
              </div>
              <div class="cnz_description">
                <p>{{ item.shortDescription }}</p>
                <a :href="'/events/' + (item.slug || item.id)"
                   target="_blank">{{ $t('home_foodvalley_description_button') }}</a>
              </div>
              <ds-button
                variant="secondary"
                :label="$t('home_foodvalley_see_all_events')"
                :size="null"
                :style="registerButtonStyle"
                @click="goToEvents"
              />
            </div>
            <div v-if="item.image" class="col-12 col-sm-12 col-md-5 col-lg-5 cnz_image"
                 :style="{backgroundImage: `url(${`/notifications/${item.id}/image`})`}"
            >
              <!--              <img :src="`/notifications/${item.id}/image`" alt="Event Image"/>-->
            </div>
            <div v-else class="col-12 col-sm-12 col-md-5 col-lg-5 cnz_image"
                 :style="{backgroundImage: `url(${`${getDefaultFallbackImageUrl}`})`}"
            >
            </div>
          </div>
        </div>
      </Container>
    </div>

    <div v-if="!fetchingAnnouncements" class="cnz__upcoming-event reverse">
      <Container>
        <div class="cnz_events">
          <div v-if="announcements.length === 0">
            <div class="cnz_last_event">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 cnz_card_info">
                <h1>{{ $t('home_foodvalley_event_title') }}</h1>
                <h4>There are no announcements right now.</h4>
                <ds-button
                  variant="secondary"
                  :label="$t('home_foodvalley_see_all_events')"
                  :size="null"
                  :style="registerButtonStyle"
                  @click="goToEvents"
                />
              </div>
            </div>
          </div>
          <div v-for="item in announcements" class="cnz_last_event">
            <div class="col-12 col-sm-12 col-md-7 col-lg-7 cnz_card_info">
              <h1>NEW ANNOUNCEMENT</h1>
              <h1>{{ item.title }}</h1>
              <div class="cnz_description">
                <p>{{ item.shortDescription }}</p>
                <a :href="'/events/' + (item.slug || item.id)"
                   target="_blank">{{ $t('home_foodvalley_description_button') }}</a>
              </div>
              <ds-button
                variant="secondary"
                label="See All Announcements"
                :size="null"
                :style="registerButtonStyle"
                @click="goToEvents"
              />
            </div>
            <div v-if="item.image" class="col-12 col-sm-12 col-md-5 col-lg-5 cnz_image"
                 :style="{backgroundImage: `url(${`/notifications/${item.id}/image`})`}"
            >
            </div>
            <div v-else class="col-12 col-sm-12 col-md-5 col-lg-5 cnz_image"
                 :style="{backgroundImage: `url(${`${getDefaultFallbackImageUrl}`})`}"
            >
            </div>
          </div>
        </div>
      </Container>
    </div>

    <Container>
      <div v-if="resources.length" class="cnz__communities">
        <h1>Interesting resources</h1>
        <p v-html="interestingResourcesDescription"></p>

        <Carousel
          :key="'carousel-communities-resources'"
          :items-to-show="2"
          :mouse-drag="false"
          :settings="carouselSettings(2)"
          :breakpoints="carouselBreakpoints(2)"
        >
          <Slide
            v-for="(resource, index) in resources"
            :key="'resource-communities-' + index"
          >
            <div @click="goToKnowledgeBase"
                 style="width: 100%; height: calc(100% - 20px); padding: 20px 10px 20px 10px; cursor: pointer;">
              <div class="resource--card">
                <h3 style="text-align: left">{{ resource.title }}</h3>
                <p style="text-align: left; font-size: 13px;">{{ resource.body.substring(0, 500) }}</p>
              </div>
            </div>
          </Slide>
          <template #addons>
            <Navigation v-if="resources.length > 2"/>
          </template>
        </Carousel>
      </div>
    </Container>

    <Container>
      <template v-if="$store.getters.isEcAerospace">
        <div class="spotting-areas-highlighted__section reverse" style="margin-top: 10px;">
          <div class="spotting-areas-highlighted__background"></div>
          <div class="spotting-areas-highlighted__section__container">
            <div class="spotting-areas-highlighted__section" style="width: 48%">
              <div class="spotting-areas-title">
                Aerospace in Canterbury
              </div>
              <div class="spotting-areas-highlighted">
                <div class="highlighted_spotting-area__content">
                  <p v-html="'With clear airways, proximity to international air and seaports, and access to infrastructure and talent, Ōtautahi Christchurch is a hub for aerospace and future transport innovation - the opportunities and growth are endless.\n'+
'A mix of start-ups and entrepreneur-driven companies are fuelling the transition from fossils to renewable, improving the way we move, and using data to create a better future that is out of this world.'"></p>
                  <!--                  <ds-button
                                      variant="rounded"
                                      :style="{backgroundColor: '#ff3d3d'}"

                                      label="Read more"
                                    ></ds-button>-->
                  <a href="https://www.christchurchnz.com/business/business-clusters/aerospace-and-future-transport" style="color: #ff3d3d;">Read more</a>
                </div>
              </div>
            </div>
            <div
              :style="{ backgroundImage: `url('/images/static-home-page/ec-health/canterbury.jpg')` }"
              class="highlighted_spotting-area__content image"
              style="width: 50%; height: 350px; background-repeat: no-repeat; background-size: cover; align-self: center;"
              :class="{ 'full-size': true}"
            >
              <div
                :style="{ backgroundImage: `url(/images/static-home-page/ec-health/canterbury.jpg)`}"
                class="simplified-connection__logo"
                :class="{ 'full-size': true}"
              />
            </div>
          </div>
        </div>
      </template>
    </Container>

    <Container>
      <template v-if="highlightedSpottingArea.id">
        <div class="spotting-areas-highlighted__section" style="margin-top: 50px;">
          <div class="spotting-areas-highlighted__background"></div>
          <div class="spotting-areas-highlighted__section__container">
            <div class="spotting-areas-highlighted__section" style="width: 48%">
              <div class="spotting-areas-title">
                {{ highlightedSpottingArea.name }}
              </div>
              <div class="spotting-areas-highlighted">
                <div class="highlighted_spotting-area__content">
                  <p v-html="highlightedSpottingArea.description"></p>
                  <ds-button
                    class="highlight-spotting-area-button"
                    icon="location-marker"
                    :icon-fill-color="'#ffffff'"
                    v-if="highlightedSpottingArea.id"
                    :style="{backgroundColor: highlightedSpottingArea.highlight_color}"
                    @click="goToHighlightedSpottingAreaDetail"
                    :label="highlightedSpottingArea.name"
                  ></ds-button>
                </div>
              </div>
            </div>
            <div
              v-if="(highlightedSpottingAreaImage || getDefaultFallbackImageUrl())"
              :style="{ backgroundImage: `url(${highlightedSpottingAreaImage || getDefaultFallbackImageUrl()})` }"
              class="highlighted_spotting-area__content image"
              style="width: 50%; height: 350px; background-repeat: round; align-self: center;margin-left: 30px;"
              :class="{ 'full-size': true, 'fallback-image': !highlightedSpottingAreaImage}"
            >
              <div
                v-if="(highlightedSpottingAreaImage || getDefaultFallbackImageUrl())"
                :style="{ backgroundImage: `url(${highlightedSpottingAreaImage || getDefaultFallbackImageUrl()})`}"
                class="simplified-connection__logo"
                :class="{ 'full-size': true, 'fallback-image': !highlightedSpottingAreaImage}"
              />
            </div>
          </div>
        </div>
      </template>
    </Container>

    <Container class="cnz-orchestrators" v-if="showWhoWeAreSection">
      <h1 class="mb-05 mt-4rem">{{ whoWeAreTitle }}</h1>
      <div class="image-container" v-if="$store.getters.isEcHealth">
        <img src="/images/static-home-page/ec-health/christchurchNZ.png"/>
        <img src="/images/static-home-page/ec-health/medtech-iq.png"/>
      </div>
      <div class="image-container-ec-aerospace" v-else-if="$store.getters.isEcAerospace">
        <div class="cnz_who_section">
          <div class="cnz_who_section--card cluster">
            <div class="cnz_who_section_image_container">
              <img src="/images/static-home-page/ec-health/christchurchNZ.png"/>
            </div>
            <p style="font-size: 16px;">ChristchurchNZ is the sustainable economic development agency for the city and all of our work is focused on making Ōtautahi Christchurch a
              better place to live, work and play. </p>
          </div>

          <div class="cnz_who_section--card cluster">
            <div class="cnz_who_section_image_container">
              <img src="/images/static-home-page/ec-health/aerospace-nz-logo.png"/>
            </div>
            <p style="font-size: 16px;">AerospaceNZ is New Zealand’s national industry body for the Aerospace sector whose goal is to build an aerospace nation. </p>
          </div>
        </div>
      </div>
    </Container>

    <SimplifiedFooter :with-margin="false"/>
  </div>
</template>

<script lang="ts">
  import SimpleHero from '../SimpleHero/SimpleHero.vue'
  import Avatar from '../Avatar/Avatar.vue'
  import { fetchPublicNotifications } from '../../api/notifications.js'
  import DsButton from '../DsButton/DsButton.vue'
  import SimplifiedCommunityCard from '../Simplified/SimplifiedCommunityCard.vue'
  import { getDefaultFallbackImageUrl, getImageForCommunity, getImageForSpottingArea } from '../../util/helpers.js'
  import SimplifiedFooter from '../SimplifiedFooter/SimplifiedFooter.vue'
  import { fetchStatsData } from '../../api/homepage.js'
  import Container from '../Container/Container.vue'
  import { getFormatEventDate } from '../../util/date.js'
  import moment from 'moment'
  import { defineComponent } from "vue";
  import { trackMatomoEvent } from '@/util/analytics'
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '@/constants/analytics-constants'
  import FacetCard from '@/pages/Simplified/FacetCard.vue'
  import { getTranslationsForTaxonomyValue } from '@/store/modules/taxonomies'
  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '@/store/modules/filters'
  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '@/store/modules/actors'
  import AnnouncementOrResourceCard from '@/components/Simplified/AnnouncementOrResourceCard.vue'
  import { fetchKnowledgeBaseData, likeArticle } from '@/api/knowledge-base'
  import { Carousel, Navigation, Slide } from 'vue3-carousel'
  import CarouselMixin from '@/util/CarouselMixin'
  import { fetchActors } from '@/api/actors'
  import SimplifiedImageCard from '@/components/Connection/SimplifiedImageCard.vue'
  import SimplifiedImageCardWithCta from '@/components/SpottingAreas/SimplifiedImageCardWithCta.vue'
  import AnnouncementCard from '@/components/Simplified/AnnouncementCard.vue'
  import CommunityCard from '@/components/Simplified/CommunityCard.vue'
  import aerospaceInCanterburyImage from '../../../../../public/images/static-home-page/ec-health/canterbury.jpg'

  export default defineComponent({
    mixins: [CarouselMixin],
    data: () => {
      return {
        events: [],
        announcements: [],
        fetchingEvents: false,
        fetchingAnnouncements: false,
        latestActors: [],
        latestProducts: [],
        allActors: [],
        facetCounts: [],
        resources: [],
        firstColumnActors: [],
        secondColumnActors: [],
        thirdColumnActors: [],
        fourthColumnActors: [],
      }
    },
    computed: {
      actorNumbersTitle() {
        if (this.$store.getters.isEcHealth) {
          return 'Number of Members in the Canterbury Health Innovation community'
        } else if (this.$store.getters.isEcAerospace) {
          return 'The New Zealand Aerospace Ecosystem at a glance'
        }
      },
      showWhoWeAreSection() {
        return this.$store.getters.isEcAerospace
      },
      whoWeAreTitle() {
        if (this.$store.getters.isEcHealth) {
          return 'The Canterbury Health Innovation Ecosystem is powered by a network of partners'
        } else if (this.$store.getters.isEcAerospace) {
          return 'The New Zealand Aerospace Ecosystem Platform is powered by a network of partners'
        }
      },
      interestingResourcesDescription() {
        if (this.$store.getters.isEcHealth) {
          return 'Explore resources that have been valuable to your community peers.'
        } else if (this.$store.getters.isEcAerospace) {
          return 'Explore resources that have been valuable to your community peers.'
        }
      },
      clickAndExploreTitle() {
        if (this.$store.getters.isEcHealth) {
          return 'Connect, innovate, and grow in just a few clicks'
        } else if (this.$store.getters.isEcAerospace) {
          return 'Connect, innovate, and grow in just a few clicks'
        }
      },
      clickAndExploreDescription() {
        if (this.$store.getters.isEcHealth) {
          return 'Canterbury Health Innovation is a thriving community of professionals in Health and Health Innovation.\n' +
            '            Explore the ecosystem to build connections and capabilities to\n' +
            '            be\n' +
            '            inspired and grow.'
        } else if (this.$store.getters.isEcAerospace) {
          return 'The New Zealand Aerospace Ecosystem Platform is a thriving community of professionals in Aerospace Innovation. Explore the ecosystem to build connections and capabilities, to be inspired, and to grow.'
        }
      },
      cardData() {
        if (this.$store.getters.isEcHealth) {
          return [
            {
              key: 'latest_actors_1',
              title: 'Startups & Scaleups',
              description: 'Connect with innovative startups and find the right partners to co-create. Discover new opportunities and build strong collaborations.',//'Access resources, mentorship, and partners to bring your health solutions to market',
              subtitle: 'Latest actors',
              buttonHref: '/actors-simplified',
              buttonText: 'view more',
            },
            {
              key: 'latest_actors_2',
              title: 'Industry Partners',
              description: 'Engage with experienced industry leaders and connect with innovation-driven companies. Build valuable relationships to accelerate.', //'Discover breakthrough technologies and collaborate with Canterbury\'s leading health innovators',
              subtitle: 'Latest actors',
              buttonHref: '/actors-simplified',
              buttonText: 'view more',
            },
            {
              key: 'latest_actors_3',
              title: 'Researchers & Clinicians',
              description: 'Collaborate with top researchers to bring your innovations to market faster. Bridge the gap between science and business for maximum impact.', //'Connect with industry partners and access facilities to translate your research into real-world impact',
              subtitle: 'Latest actors',
              buttonHref: '/actors-simplified',
              buttonText: 'view more',
            },
            {
              key: 'latest_actors_4',
              title: 'Support Organisations',
              description: 'Ask questions and receive feedback from organisations that support innovation. Build a strong network and accelerate your innovation journey.', //'Engage with a vibrant ecosystem supporting the acceleration of world-class health solutions and the pioneers behind them',
              subtitle: 'Latest actors',
              buttonHref: '/actors-simplified',
              buttonText: 'view more',
            },
          ]
        } else if (this.$store.getters.isEcAerospace) {
          return [
            {
              key: 'latest_actors_1',
              title: 'Startups & Scaleups',
              description: 'Connect with innovative startups and find the right partners to co-create. Discover new opportunities and build strong collaborations.', //'Access resources, mentorship, and partners to bring your aerospace solutions to market',
              subtitle: 'Latest actors',
              buttonHref: '/actors-simplified',
              buttonText: 'view more',
            },
            {
              key: 'latest_actors_2',
              title: 'Industry Partners',
              description: 'Engage with experienced industry leaders and connect with innovation-driven companies. Build valuable relationships to accelerate.', //'Discover breakthrough technologies and collaborate with New Zealand’s leading aerospace innovators',
              subtitle: 'Latest actors',
              buttonHref: '/actors-simplified',
              buttonText: 'view more',
            },
            {
              key: 'latest_actors_3',
              title: 'Researchers',
              description: 'Collaborate with top researchers to bring your innovations to market faster. Bridge the gap between science and business for maximum impact.', //'Connect with industry partners and access facilities to translate your research into real-world impact',
              subtitle: 'Latest actors',
              buttonHref: '/actors-simplified',
              buttonText: 'view more',
            },
            {
              key: 'latest_actors_4',
              title: 'Support Organisations',
              description: 'Ask questions and receive feedback from organisations that support innovation. Build a strong network and accelerate your innovation journey.', //'Engage with a vibrant ecosystem supporting the acceleration of aerospace solutions and the pioneers behind them',
              subtitle: 'Latest actors',
              buttonHref: '/actors-simplified',
              buttonText: 'view more',
            },
          ]
        }
      },
      ecosystemDisplayName(): string {
        return this.$store.getters.ecosystemDisplayName
      },
      linkToMapData() {
        if (this.$store.getters.isEcHealth) {
          return {
            title: "MedTechIQ",
            description: "In New Zealand, we’re known for defying the odds with leading-edge thinking from the edge of the world. Despite being small, we’re already making big leaps in medical devices and digital health technology. But imagine what we could achieve if we put our minds together. \n" +
              "\n" +
              "Medtech-iQ Aotearoa is here to unlock New Zealand’s MedTech innovation potential, building a national innovation hub that sparks creativity, develops capability, and drives economic growth. We provide direction, connection, coordination and support to all our MedTech innovators. \n" +
              "\n" +
              "Through the power of our collective intelligence, we can accelerate the success of the entire medical technology sector - and in doing so create better outcomes for our people, our communities, and our country. ",
            url: "https://static.datascouts.eu/medtech-iq-cc-focus.html",
            buttonText: "Visit Map",
            image: "/images/static-home-page/ec-health/medtech-iq.png",
          }
        } else if (this.$store.getters.isEcAerospace) {
          return {
            title: "AeroSpace",
            description: "In New Zealand, we’re known for defying the odds with leading-edge thinking from the edge of the world. Despite being small, we’re already making big leaps in medical devices and digital health technology. But imagine what we could achieve if we put our minds together. \n" +
              "\n" +
              "Medtech-iQ Aotearoa is here to unlock New Zealand’s MedTech innovation potential, building a national innovation hub that sparks creativity, develops capability, and drives economic growth. We provide direction, connection, coordination and support to all our MedTech innovators. \n" +
              "\n" +
              "Through the power of our collective intelligence, we can accelerate the success of the entire medical technology sector - and in doing so create better outcomes for our people, our communities, and our country. ",
            url: "https://static.datascouts.eu/medtech-iq-cc-focus.html",
            buttonText: "Visit Map",
            image: "/images/static-home-page/ec-health/medtech-iq.png",
          }
        } else if (this.$store.getters.isEcCleantech) {

        } else if (this.$store.getters.isAgritechHub) {

        } else {
          return {
            title: "MedTechIQ",
            description: "The MedTechIQ AeroSpaceNZ Map is a detailed visualization of key locations and organizations driving innovation in New Zealand's aerospace and medical technology sectors. Designed to provide clear insights into the regional landscape, this map serves as a valuable reference for professionals, researchers, and stakeholders seeking to navigate and connect with the industry's most impactful hubs.",
            url: "https://static.datascouts.eu/medtech-iq-cc-focus.html",
            buttonText: "Visit Map",
            image: "/images/static-home-page/ec-health/medtech-iq.png",
          }
        }
      },
      getFirstColumnActorPortfolio() {
        if (this.$store.getters.isEcHealth) {
          return "GmVBL32kXP"
        } else if (this.$store.getters.isEcAerospace) {
          return "E3naMZKn1M"
        } else if (this.$store.getters.isEcCleantech) {
          return ""
        } else if (this.$store.getters.isAgritechHub) {
          return ""
        } else {
          return "wVdp1MPpaE"
        }
      },
      getSecondColumnActorPortfolio() {
        if (this.$store.getters.isEcHealth) {
          return "WKkNM6Wnzw"
        } else if (this.$store.getters.isEcAerospace) {
          return "b3koWPMkWr"
        } else if (this.$store.getters.isEcCleantech) {
          return ""
        } else if (this.$store.getters.isAgritechHub) {
          return ""
        } else {
          return "wVdp1MPpaE"
        }
      },
      getThirdColumnActorPortfolio() {
        if (this.$store.getters.isEcHealth) {
          return "EGnR8BKxa7"
        } else if (this.$store.getters.isEcAerospace) {
          return "oBnPB2gxq1"
        } else if (this.$store.getters.isEcCleantech) {
          return ""
        } else if (this.$store.getters.isAgritechHub) {
          return ""
        } else {
          return "wVdp1MPpaE"
        }
      },
      getFourthColumnActorPortfolio() {
        if (this.$store.getters.isEcHealth) {
          return "9jVOM6WkOv"
        } else if (this.$store.getters.isEcAerospace) {
          return "mJn5vPWkor"
        } else if (this.$store.getters.isEcCleantech) {
          return ""
        } else if (this.$store.getters.isAgritechHub) {
          return ""
        } else {
          return "wVdp1MPpaE"
        }
      },
      highlightedSpottingArea() {
        return this.$store.state.spottingAreas.highlightedSpottingArea.data
      },
      highlightedSpottingAreaImage() {
        return getImageForSpottingArea(this.highlightedSpottingArea)
      },
      registerButtonStyle() {
        return {
          backgroundColor: '#2b4557',
          color: 'white',
          fontFamily: '',
          fontWeight: 'bold',
          height: 'auto',
          width: '',
          textTransform: 'capitalize',
          borderRadius: '4px',
          alignSelf: 'center',
          textAlign: 'center',
          marginTop: '50px',
          fontSize: '16px',
        }
      },
      hasAccessToCommunities() {
        return this.$store.getters.hasAccessToCommunities
      },
      communities() {
        // return this.$store.state.communities.listData.data.slice(0, 3)
        return this.$store.state.communities.listData.data
      },
    },
    methods: {
      goToKnowledgeBase() {
        this.$router.push('/dashboards/knowledge-base')
      },
      likeArticle({ resourceId, status }) {
        likeArticle(resourceId, status)
      },
      fetchLatestNews() {
        return fetchKnowledgeBaseData({ limit: 10, offset: 0, relevantOnly: true, tags: 871 })
          .then(news => {
            var items = news
              .data.results
              .map(response => {
                if (['rss', 'pr', 'blog'].includes(response.media_type) && response.sql_media_id) {
                  response.detail_link = '/files/' + response.sql_media_id
                }
                return response
              })

            this.resources = items
          })
          .catch(error => {
            console.log(error)
          })
      },
      facetCardClick(facet) {
        this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
        this.$store.commit(FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM, {
          facet: facet.facetType,
          value: facet.value,
        })

        const listFilters = { ...this.$store.getters.listFilterObject }

        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_LIST, listFilters)

        if (this.isMember) {
          this.$router.push('/actors')
        } else {
          this.$router.push('/actors-simplified')
        }
      },
      fetchFacetCounts() {
        const parameters = {}

        fetchStatsData(parameters)
          .then(response => {
            const resultOrdered = response.data.stats.map(result => {
              const translations = getTranslationsForTaxonomyValue(response.data.property, result.value)
              let label = ''

              if (translations && translations[this.locale] && translations[this.locale].name) {
                label = translations[this.locale].name
              } else {
                label = translations['en'].name
              }

              result['label'] = label
              result['count'] = result.total
              result['facetName'] = response.data.property

              return result
            })

            // Only return the top 6
            this.facetCounts = resultOrdered
              .sort((a, b) => b.count - a.count)
              .slice(0, 6)
          })
          .catch(errors => {
            //
          })
      },
      goToHighlightedSpottingAreaDetail() {
        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.HIGHLIGHTED_SPOTTING_AREA, MATOMO_EVENT_ACTIONS.HOMEPAGE_BUTTON, this.highlightedSpottingArea.id)
        this.$router.push(`/spotting-areas/${this.highlightedSpottingArea.id}`)
      },
      formatEventDate(date, format = 'ddd, Do MMM YYYY, hh:mmA') {
        date = moment(date).format() // Date() has issues cross browser if it's not a standard ISO format: https://stackoverflow.com/questions/39969570/deprecation-warning-in-moment-js-not-in-a-recognized-iso-format
        return moment(new Date(date).toLocaleString('en-US', { timeZone: 'Europe/Brussels' })).format(format)
      },
      getFormatEventDate,
      getImageForCommunity,
      getDefaultFallbackImageUrl,
      goToActorDetailPage(actor) {
        this.$router.push(`/actors/${actor.id}`)
      },
      goToAnnouncementDetailPage(announcement) {
        this.$router.push(`/announcements/${announcement.slug || announcement.id}`)
      },
      goToViewMore(data) {
        this.$router.push(data.buttonHref)
      },
      goToEvents() {
        this.$router.push('/events-simplified')
      },
      goToAnnouncements() {
        this.$router.push('/announcements-simplified')
      },
      fetchEvents() {
        this.fetchingEvents = true

        return fetchPublicNotifications(1, 0, { is_event: true, upcoming_events: true })
          .then(response => {
            this.events = response
            this.fetchingEvents = false
          })
          .catch(error => {
            this.fetchingEvents = false

            console.log(error)
          })
      },
      fetchAnnouncements() {
        this.fetchingAnnouncements = true

        return fetchPublicNotifications(1, 0, { is_event: false })
          .then(response => {
            this.announcements = response
            this.fetchingAnnouncements = false
          })
          .catch(error => {
            this.fetchingAnnouncements = false

            console.log(error)
          })
      },
      async fetchFirstColumnActors() {
        let actorsParameters = { limit: 3, portfolio: this.getFirstColumnActorPortfolio };
        await fetchActors(actorsParameters).then((actors) => {
          this.firstColumnActors = actors.data;
        });
      },
      async fetchSecondColumnActors() {
        let actorsParameters = { limit: 3, portfolio: this.getSecondColumnActorPortfolio };
        await fetchActors(actorsParameters).then((actors) => {
          this.secondColumnActors = actors.data;
        });
      },
      async fetchThirdColumnActors() {
        let actorsParameters = { limit: 3, portfolio: this.getThirdColumnActorPortfolio };
        await fetchActors(actorsParameters).then((actors) => {
          this.thirdColumnActors = actors.data;
        });
      },
      async fetchFourthColumnActors() {
        let actorsParameters = { limit: 3, portfolio: this.getFourthColumnActorPortfolio };
        await fetchActors(actorsParameters).then((actors) => {
          this.fourthColumnActors = actors.data;
        });
      },
      async fetchLatestActors() {
        await Promise.all([
          this.fetchFirstColumnActors(),
          this.fetchSecondColumnActors(),
          this.fetchThirdColumnActors(),
          this.fetchFourthColumnActors(),
        ]);
      },
      /*fetchLatestProducts() {
        const actorsParameters = { limit: 0, offset: 3, portfolio: this.getSecondColumnActorPortfolio } // L0V4v3Lkb7

        fetchActors(actorsParameters)
          .then((actors) => {
            this.latestProducts = actors.data
          })
      },
      fetchAllActors() {
        const actorsParameters = { limit: 0, offset: 3, portfolio: this.getFourthColumnActorPortfolio } // aAVbz5OVmr

        fetchActors(actorsParameters)
          .then((actors) => {
            this.allActors = actors.data
          })
      },*/
    },
    async mounted() {
      await this.fetchLatestActors()
      /*this.fetchLatestProducts()
      this.fetchAllActors()*/
      this.fetchEvents()
      this.fetchFacetCounts()
      this.fetchAnnouncements()
      this.fetchLatestNews()
    },
    components: {
      CommunityCard,
      AnnouncementCard,
      SimplifiedImageCardWithCta,
      SimplifiedImageCard,
      AnnouncementOrResourceCard,
      FacetCard,
      SimplifiedCommunityCard,
      SimpleHero,
      Avatar,
      DsButton,
      SimplifiedFooter,
      Container,
      Carousel,
      Slide,
      Navigation,
      aerospaceInCanterburyImage
    },
  })
</script>


<style lang="scss" scoped>
  @import "resources/assets/scss/variables";
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

  .mb-05 {
    margin-bottom: .5rem;
  }

  .mt-4rem {
    margin-top: 4rem;
  }

  .cnz__info--click-explore {
    background-color: #231F20;
    padding-bottom: 1rem;
    color: white;
  }

  :deep(.category-stats-title) {
    font-size: 14px !important;
    font-family: "DM Sans", sans-serif !important;
  }

  .cnz {
    .cnz-orchestrators {
      width: 100%;
      text-align: center;
      margin-top: 4rem;
      margin-bottom: 4rem;

      .image-container-ec-aerospace {
        margin-top: 3rem;

        .cnz_who_section {
          justify-content: space-evenly;

          .cnz_who_section--card {
            display: flex !important;
            flex-direction: column;
            align-content: center;
            text-align: center;
            height: auto !important;

            &:first-child {
              img {
                padding: 25px;
              }
            }

            .cnz_who_section_image_container {
              height: 100px;
            }
          }
        }
      }


      .image-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 50%;
        margin: 0 auto;

        img {
          &:first-child {
            width: 250px;
            height: 50px;
          }

          align-self: center;
          width: 200px;
        }
      }
    }

    .cnz_who_section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      .cnz_who_section--card.cluster {
        box-shadow: 0 0 20px 0 rgba(27, 50, 67, .15);
        max-width: 400px;
        height: 400px;
        max-height: 450px;

        img {
          width: 200px;
        }
      }

      .cnz_who_section--card {
        text-align: center;
        background-color: white;
        border-radius: 8px;
        padding: 1rem;
        max-width: 300px;
        margin-bottom: 1rem;

        h4 {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .cnz.scrollable {
    h1 {
      font-size: 48px;
      font-family: "DM Sans", sans-serif;
    }

    h5 {
      font-size: 16px;
      font-family: "DM Sans", sans-serif;
    }

    p {
      font-size: 18px;
      font-family: "Work Sans", sans-serif;
    }

    .mb-05 {
      margin-bottom: .5rem;
    }
  }

  .cnz__info {
    margin-bottom: 4em;

    .cnz__cards {
      display: flex;
      justify-content: center;
      margin-top: -100px;
      gap: 1rem;

      .cnz__card {
        width: 500px;
        max-width: 340px;
        min-height: 400px;
        background-color: white;
        height: 100%;
        border-radius: 8px;
        padding: 30px 20px 20px 20px;
        position: relative;
        color: #231F20;
        box-shadow: 0 0 20px 0 rgba(27, 50, 67, .15);

        .line {
          width: 25px;
          height: 4px;
          margin-top: 0.3rem;
          background-color: #ED1C24;
        }

        .cnz__card_content {
          margin-top: 20px;

          .avatar {
            background-color: rgba(33, 36, 74, .20) !important;
          }
        }

        h2 {
          color: black;
        }

        h4 {
          color: black;
        }

        .cnz__card_item {
          display: flex;
          margin-bottom: 25px;
          align-items: center;

          &:hover {
            cursor: pointer;
          }

          p {
            margin: 0 10px;
            color: black;
            font-size: 14px;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .avatar {
            width: 35px;
            height: 35px;
            background-color: rgba(255, 255, 255, .20) !important;

            :deep(.avatar__img) {
              width: 35px;
              height: 35px;
            }
          }
        }

        p {
          margin: 20px 0 20px 0;
          color: black;
        }

        .static-home-foodleap__card_button {
          cursor: pointer;

          p {
            color: #ED1C24;
            text-decoration: underline;
            font-size: 14px;
          }
        }
      }
    }
  }

  .cnz__upcoming-event.reverse {
    background-color: #231f20;

    h1:first-child {
      text-transform: uppercase;
      color: white !important;
      font-weight: 400;
      font-size: 24px;
    }

    h1 {
      color: white;
    }

    p {
      color: white;
    }
  }

  .cnz__upcoming-event {
    background-color: #c8d8d2;
    margin-bottom: 3rem;

    .cnz_events {
      padding: 3em 1em;

      .cnz_last_event.reverse {
        flex-direction: row-reverse;

        .cnz_card_info {
          h1 {
            color: black;
          }

          p {
            color: black;
          }

          a {
            color: black;
          }
        }

        h1:first-child {
          text-transform: uppercase;
          color: black !important;
          font-weight: 400;
          font-size: 24px;
        }

        h1 {
          color: black;
        }

        p {
          color: black;
        }
      }

      .cnz_last_event {
        display: flex;

        .cnz_card_info {
          h1:first-child {
            text-transform: uppercase;
            color: #707070;
            font-weight: 400;
            font-size: 24px;
          }

          h1 {
            color: white;
          }

          p {
            color: white;
          }

          a {
            color: white;
          }

          h1:nth-child(2) {
            margin-top: 10px;
          }

          .cnz__date {
            display: flex;
            align-items: center;
            margin-top: 10px;

            p {
              margin-left: 10px;
              font-size: 14px;
            }
          }

          .cnz_description {
            color: #707070;
            margin-top: 20px;

            p {
              white-space: pre-line;
              width: 97%;
              font-size: 14px;
            }
          }
        }

        .cnz_image {
          margin-top: 20px;
          width: 430px;
          height: 430px;
          border: 8px solid white;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }

  .cnz__communities {
    margin-top: 5rem;
    margin-bottom: 5rem;

    .resource--card {
      box-shadow: 0 0 20px 0 rgba(27, 50, 67, .05);
      padding: 1rem;
      min-height: 240px;
      max-height: 240px;

      &:hover {
        box-shadow: 0 0 20px 0 rgba(27, 50, 67, .15);
      }
    }

    h1 {
      color: black;
      text-align: center;
    }

    p {
      text-align: center;
      margin-top: 20px;
      color: #707070;
    }

    .cnz__communities_list {
      width: 340px;
    }

    .cnz__communities_list_view {
      overflow-y: auto;
      padding: 20px;
      display: flex;
      margin-top: 3rem;
      gap: 2rem;
    }
  }

  .cnz {
    @media(max-width: $screen-xl) {
      .cnz__cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 2rem;
      }

      .cnz__communities_list_view {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 2rem;
      }
    }

    @media(max-width: $screen-lg) {
      .cnz__cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem;
        margin-top: -100px;
        align-items: center;

        .cnz__card {
          width: 100% !important;
          margin: 20px;
          max-width: 100% !important;
        }
      }

      .cnz_events {
        display: flex;
        flex-direction: column;
        padding: 3em 2em;

        .cnz_last_event {
          display: flex;
          flex-direction: column;

          .cnz_card_info .cnz_description p {
            width: 100%;
          }
        }

        .cnz_image {
          margin-top: 20px;
        }

        .static-home-foodleap_information {
          p {
            width: 100%;

          }
        }
      }

      .cnz__communities_list {
        width: 300px;
      }

      .cnz__communities_list_view {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2rem;
      }

    }
  }

  @media(max-width: $screen-md-max) {
    .cnz__cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2rem;
      align-items: normal !important;

      .cnz__card {
        width: 100% !important;
        margin: 20px;
      }
    }

    .cnz_events {
      display: flex;
      flex-direction: column;
      padding: 3em 2em;

      .cnz_last_event {
        display: flex;
        flex-direction: column;

        .cnz_card_info .cnz_description p {
          width: 100%;
        }
      }

      .cnz_image {
        margin-top: 20px;
      }

      .static-home-foodleap_information {
        p {
          width: 100%;

        }
      }
    }

    .cnz__communities_list {
      width: 200px;
    }

    .cnz__communities_list_view {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2rem;
    }
  }

  .spotting-areas-highlighted__section__container {
    display: flex;
    flex-direction: row;

    padding-top: 50px;
    padding-bottom: 50px;
  }

  .spotting-areas-highlighted__section.reverse {

    .spotting-areas-highlighted__background {
      background-color: #231F20;
    }

    .spotting-areas-highlighted__section__container {
      flex-direction: row-reverse;
      justify-content: space-between;
      color: white;

      .spotting-areas-title {
        color: white;
      }
    }
  }

  .spotting-areas-highlighted__section {
    position: relative;

    .spotting-areas-highlighted__background {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      overflow: hidden;
      width: 100vw;
      height: 100%;
      background-color: #e9e9e9;
      z-index: -1;
    }

    .spotting-areas-highlighted {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      padding-bottom: 50px;

      .highlight-spotting-area-button {
        margin-left: 0;
        padding: 10px 15px 10px 15px !important;

        :deep(.button__label) {
          padding-left: 15px !important;
        }
      }

      :deep(ul) {
        list-style-type: disc !important;
        margin-left: 15px;
      }

      .highlighted_spotting-area__content {

        &.image {
          background-size: cover;
          padding-top: 50px;
        }

        h1 {
          margin-bottom: 12px;
        }

        p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 12;
          position: relative;
          text-overflow: ellipsis;


          max-height: 300px;
          list-style-type: disc;
        }
      }
    }
  }
</style>
